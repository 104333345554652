@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&display=swap');
/* @import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css); */
@import url('https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css');
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* default settings */
@layer base {
  html {
    /* font-family: 'Poppins', sans-serif; */
    /*font-family: 'Nanum Gothic', 'Poppins', arial, helvetica, sans-serif !important; */
    /* font-family:  'NanumSquare', sans-serif !important; */
    /* font-family: 'Spoqa Han Sans Neo', 'Poppins', arial, helvetica, sans-serif !important; */
    font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;


    @apply bg-white text-konamain;
    @apply scroll-smooth;
  }

  .no-tailwindcss-base, .no-tailwindcss-base * , .no-tailwindcss-base > * {
    font-size: revert;
    font-weight: revert;
    margin: revert;
    display: revert;
    vertical-align: revert;
    max-width: revert;
    height: revert;
    border-width: revert;
    border-style: revert;
    border-color: revert;
    border-style: revert;
    outline: revert;
    list-style: revert;
    padding: revert;
  }
}


#root { @apply text-sm h-screen; }


/* CUSTOM */
.fadeIn {
  animation: _fadeIn 0.4s linear;
}
@keyframes _fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


/* ripple styles*/
.ripple-gray {@apply bg-gray-300; background-position: center; transition: background 0.6s;}
.ripple-gray:hover {background: #9ca3af radial-gradient(circle, transparent 1%, #9ca3af 1%) center/15000%;}
.ripple-gray:active {background-color: transparent; background-size: 100%; transition: background 0s;}

.ripple-gray-outline {@apply bg-transparent text-konagray border; background-position: center; transition: background 0.6s;}
.ripple-gray-outline:hover {@apply text-konagray; background: #f3f4f6 radial-gradient(circle, transparent 1%, #f3f4f6 1%) center/15000%;}
.ripple-gray-outline:active {background-color: transparent; background-size: 100%; transition: background 0s;}

.ripple-primary {@apply bg-konared; background-position: center; transition: background 0.6s;}
.ripple-primary:hover {background: #b91c1c radial-gradient(circle, transparent 1%, #b91c1c 1%) center/15000%;}
.ripple-primary:active {@apply bg-konared; background-size: 100%; transition: background 0s;}

.ripple-primary-outline {@apply bg-transparent text-konared; background-position: center; transition: background 0.6s;}
.ripple-primary-outline:hover {@apply text-white; background: #FF3C42 radial-gradient(circle, transparent 1%, #FF3C42 1%) center/15000%;}
.ripple-primary-outline:active {background-color: transparent; background-size: 100%; transition: background 0s;}

.ripple-blue {@apply bg-indigo-600; background-position: center; transition: background 0.6s;}
.ripple-blue:hover {background: #3730a3 radial-gradient(circle, transparent 1%, #3730a3 1%) center/15000%;}
.ripple-blue:active {@apply bg-indigo-600; background-size: 100%; transition: background 0s;}

/* slider styles */

.sim-input-slider {
  height: 24px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
.sim-input-slider:focus {
  outline: none;
}
.sim-input-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #FFD1D1;
  border-radius: 1px;
  border: 0px solid #000000;
}
.sim-input-slider::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #FF3C42;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #FF3C42;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}
.sim-input-slider:focus::-webkit-slider-runnable-track {
  background: #FFD1D1;
}
.sim-input-slider::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #FFD1D1;
  border-radius: 1px;
  border: 0px solid #000000;
}
.sim-input-slider::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #FF3C42;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #FF3C42;
  cursor: pointer;
}
.sim-input-slider::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.sim-input-slider::-ms-fill-lower {
  background: #FFD1D1;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
.sim-input-slider::-ms-fill-upper {
  background: #FFD1D1;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
.sim-input-slider::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #FF3C42;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #FF3C42;
  cursor: pointer;
}
.sim-input-slider:focus::-ms-fill-lower {
  background: #FFD1D1;
}
.sim-input-slider:focus::-ms-fill-upper {
  background: #FFD1D1;
}









.p-kr {word-break: keep-all;  line-break: strict;}

.slideDown {
  -moz-transition: height .5s;
  -ms-transition: height .5s;
  -o-transition: height .5s;
  -webkit-transition: height .5s;
  transition: height .5s;
  height: 0;
  overflow: hidden;
}

.tbl-about {@apply w-full border-collapse border;}
.tbl-about > thead {@apply  shadow-sm;}
.tbl-about th {@apply border border-indigo-400 bg-indigo-600 p-2  text-center font-bold hover:cursor-pointer  text-white;}
.tbl-about td {@apply border border-indigo-400 text-center p-2;}
.tbl-about > tbody > tr > td > span{@apply inline-flex items-center space-x-1;}

.tbl-simresult {@apply w-full border-collapse border;}
.tbl-simresult > thead {@apply  shadow-sm;}
.tbl-simresult th {@apply border border-gray-300 bg-[#F5F5F5] px-2 py-4  text-center font-bold hover:cursor-pointer;}
.tbl-simresult td {@apply border border-gray-300 p-2 text-center;}
.tbl-simresult > tbody > tr > td > span{@apply inline-flex items-center space-x-1;}

.tbl-simparam {@apply w-full border-collapse border;}
.tbl-simparam > thead {@apply  shadow-sm;}
.tbl-simparam th {@apply border border-gray-300 bg-gray-200 py-4 font-bold hover:cursor-pointer;}
.tbl-simparam td {@apply border border-gray-300 py-4;}
.tbl-simparam > tbody > tr > td > span{@apply inline-flex items-center space-x-1;}
/* NEW design */


/* menu bar hover */
/* .navlink-subbar {
  display:block;
  content: '';
  border-bottom: solid 3px #019fb6;  
  transform: scaleX(0);  
  transition: transform 250ms ease-in-out;
}
.navlink-sub:hover:after { transform: scaleX(1); } */




.underline-2 {@apply relative;}
.underline-2:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 208.01 8.3'%3E%3Cpath fill='%23fab758' d='M64.42,2.42Q42.78,1.46,21.19,0c-2.8-.19-4.09.89-3.87,2L3.92,1.87c-5.13-.05-5.28,3.87-.12,3.92l60.49.55c46.63,2.08,93.34,2.51,139.81,1.27,5-.13,5.39-3.87.13-3.92Z'/%3E%3C/svg%3E");
  content: "";
  position: absolute;
  z-index: -1;
  display: block;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  left: 50%;
  bottom: -0.1em;
  width: 100%;
  height: 0.3em;
  transform: translateX(-50%);
}

/* x button in input search */
.re-search-input::-webkit-search-cancel-button {
  @apply bg-konared h-6 w-6 bg-no-repeat bg-opacity-80;
  -webkit-appearance: none;
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'%3E%3Cpath fill-rule='evenodd' d='M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z' clip-rule='evenodd' /%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'%3E%3Cpath fill-rule='evenodd' d='M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z' clip-rule='evenodd' /%3E%3C/svg%3E");
}



/*start checkbox styling*/
.cbx {
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer; 
}

.cbx span:first-child {
  transform: scale(1);
  @apply relative border border-gray-400 bg-gray-50 rounded-md;
  transition: all .2s ease; 
}
  
.cbx span:first-child svg {
  position: absolute;
  z-index: 1;
  fill: none;
  stroke: white;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all .3s ease;
  transition-delay: .1s;
  transform: translate3d(0, 0, 0); 
}
.cbx span:first-child:before {
  content: "";
  @apply h-full w-full bg-konared opacity-100 rounded-md;
  transform: scale(0);
  transition-delay: .2s; 
}

.cbx:hover span:first-child {
  @apply  border-konared;
}

.inp-cbx:checked + .cbx span:first-child {
  @apply bg-konared border-konared;
  animation: check .6s ease; 
}
.inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0; 
}
.inp-cbx:checked + .cbx span:first-child:before {
  transform: scale(2.2);
  opacity: 0;
  transition: all .6s ease; 
}

@keyframes check {
  50% {
    transform: scale(1.2); 
  } 
}

/*end of check box style*/

@keyframes bounceX {
  0%, 100%  {
    transform: translateX(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }
  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  } 
}

.custom-animate-bounce-x {
  animation: bounceX 1s infinite;
}




/*START REMOVE*/
.api-index-tbl {@apply border-separate; border-spacing: 0;}
.api-index-tbl thead > tr > th {@apply text-center;}
.api-index-tbl tbody > tr > th {@apply border-t-0 border-b border-r border-l p-2 text-xs text-center font-bold hover:cursor-pointer;}
.api-index-tbl tbody > tr > td {@apply border-t-0 border-b border-r p-2 text-xs text-center hover:cursor-pointer;}
.api-index-tbl tbody > tr > :is(td:nth-child(2)) {@apply text-[#437c29];}
.api-index-tbl tbody > tr > :is(td:nth-child(3)) {@apply text-[#ee6666];}


.search-wrapper {}
.search-result li {@apply p-4 text-sm border-b hover:bg-gray-300}
.search-result :is(li:last-child) {@apply border-b-0;}